import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import BannerSection from '../containers/Saas/BannerSection';
import ServiceSection from '../containers/Saas/ServiceSection';
import FormController from '../containers/Saas/Formcontroller';
import FormCompany from '../containers/Saas/Formcompany';
import TwoblocksSection from '../containers/Saas/TwoblocksSection';
import SEO from '../components/seo';
import '../bootstrap/dist/css/bootstrap.min.css';

export default () => {

  const Data = useStaticQuery(graphql`
    query {
      allContentfulPages(filter: {slug: {eq: "home"}}) {
        edges {
          node {
            title
            slug
            metaTitle
            metaDescription
          }
        }
      }
    }
  `);

  const metaData = Data.allContentfulPages.edges[0].node;

  return (
    <>
      <SEO
        title={metaData.metaTitle}
        description={metaData.metaDescription}
      /> 
      <Layout>
        <BannerSection />
        <ServiceSection />
        <FormController />
        <FormCompany />
        <TwoblocksSection />
      </Layout>
    </>
  );
};
