import styled from 'styled-components';

const ControllerDataWrapper = styled.section`
  background-color: #f4f9fa;
  padding: 80px 0 40px;
  
  .controllerData_head {
      text-align: center;
      h2{
        font-family: "Runda W00 Bold" !important;
        padding-bottom: 10px;
      }
      h3 {
        font-size: 18px;
      }
  }

  .card-block{
     padding: 30px 0px;
    .col-md-5 {
      padding-right: 0;
    }
    .col-md-7 {
      padding-left: 0;
    }
    @media (max-width: 500px) {
      .col-md-5 {
        padding-right: 15px;
      }
      .col-md-7 {
        padding-left: 15px;
      }
    } 
  .left-card {
    background-color: #fff;
    box-shadow: 0 4px 23px 0 rgba(0,0,0,0.15);
    border-radius: 5px;
        .card-head {
          padding: 15px 20px;
          border-bottom: 1px solid #0000001f;
          h2 {
              font-size: 21px;
              font-family: "Runda W00 Bold" !important;
              padding: 0px;
              margin: 0px;
          }
        }
        .cardbody{
            min-height: 360px;
            h2 {
              font-size: 33px;
              font-family: "Runda W00 Bold" !important;
              text-align: center;
              border-bottom: 1px solid #0000001f;
              padding: 20px 0px 20px 0px;
           }
           ul{
              padding: 15px 20px;
                svg {
                  fill: rgb(32, 211, 64);
                  stroke: #fff;
                  margin-right: 25px;
                }
                li {
                    line-height: 30px;
                    font-size: 14px;
                    font-family: "Runda W00 Medium" !important;
                }
           }
        }
        .card-foot {
            text-align: center;
            padding: 20px;
            border-top: 1px solid #0000001f;
            a {
                color: #000;
                font-weight: bold;
            }
        }
    }
    .right-card{
      background-color: #243b40;
      padding: 15px 35px;
      margin-top: 7px;
      border-radius: 0px 6px 6px 0px;
      label {
          color: rgba(255, 255, 255, 0.38);
      }
      @media (max-width: 500px) {
        border-radius: 6px 6px 6px 6px;
      }
      input {
          background-color: #243b40;
          color: #dedede !important;
          border-radius: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          padding-left: 0px;
          font-family: "Runda W00 Medium" !important;
          font-size: 22px;
          :focus {
            box-shadow: none;
          }
          :-internal-autofill-selected {
              background-color: #000 !important;
          }
      }
      .submit-text{
        text-align: center;
        p {
          font-size: 19px;
          color: #fff;
          font-family: "Runda W00 bold" !important;
          margin-top: 13px;
        }
        button {
            background-color: #3f5357;
            border-color: #3f5357;
            color: #778689 !important;
            padding: 10px 0px;
            font-size: 20px;
            font-family: "Runda W00 Bold" !important;
        }
      }
      .checkbox-inline {
        margin-top: 10px;
        text-transform: capitalize;
        vertical-align: super;
          input[type="checkbox"]{
            width: 15px;
            height: 15px;
            cursor: pointer;
            vertical-align: sub;
            margin-right: 10px;
          }
          a {
            color: #fff !important;
          }
      } 
    }
} `;

export default ControllerDataWrapper;
