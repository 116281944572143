import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from '../../../reusecore/src/elements/Box';
import ControllerDataWrapper from './controller.style';
import { Container, Col, Row } from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import {ic_chat_bubble_outline} from 'react-icons-kit/md/ic_chat_bubble_outline';
import { Link } from "gatsby";
const ServiceSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulFormFields {
        edges{
          node{
            sectionTitleFormOne
            sectionSubTitleFormOne
            leftBlockTitleFormOne
            leftBlockSubTitleFormOne
            leftBlockListingFormOne
            leftBlockLinkFormOne
            leftBlockCaptionFormOne
            placeOrderInputField
            nameFieldTextFormOne
            emailFieldTextFormOne
            phoneFieldTextFormOne
            checkboxFieldTextFormOne
            buttonCaptionTextFormOne
            afterButtonHelplineTextFormOne
          }
        }
      }
    }
  `);

  const controllerData = Data.allContentfulFormFields.edges[0].node;

  return (
    <ControllerDataWrapper>
      <Container>
        <Box className="controllerData_head">
           <h2>{controllerData.sectionTitleFormOne}</h2>
           <h3>{controllerData.sectionSubTitleFormOne}</h3>
        </Box>
      </Container>
      <div className="card-block">
      <Container>
        <Row>
             <Col md={5} xs={12}>
              <div className="left-card">
                <div className="card-head">
                  <h2>{controllerData.leftBlockTitleFormOne}</h2>
                </div> 
                <div className="cardbody">
                  <h2>{controllerData.leftBlockSubTitleFormOne}</h2>
                  <ul>
                      {controllerData.leftBlockListingFormOne.map((items, i) => (
                         <li><Icon icon={checkmark} size={16} /> {controllerData.leftBlockListingFormOne[i]}</li>
                      ))} 
                  </ul>
                </div>
                <div className="card-foot">
                  <Link to={controllerData.leftBlockLinkFormOne}>{controllerData.leftBlockCaptionFormOne}</Link>
                </div>
              </div> 
            </Col>
             <Col md={7} xs={12}> 
             <div className="right-card">
                  <form name="controller" action="/professionals-aanmelden-success" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                      <input type="hidden" name="bot-field" />
                      <input type="hidden" name="form-name" value="controller" />

                          <label htmlFor="inputname"></label>
                          <input
                            id="inputname"
                            className="form-control"
                            name="placeorder"
                            type="text"
                            placeholder={controllerData.placeOrderInputField}
                            required/>
                          <label htmlFor="inputname"></label>
                          <input
                            id="inputname"
                            className="form-control"
                            name="name"
                            type="text"
                            placeholder={controllerData.nameFieldTextFormOne}
                            required/>  
                          <label htmlFor="inputemail"></label>
                          <input
                            id="inputemail"
                            className="form-control"
                            name="email"
                            type="email"
                            placeholder={controllerData.emailFieldTextFormOne}
                            required/>  
                          <label htmlFor="inputphone"></label>
                          <input
                            id="inputphone"
                            className="form-control"
                            name="phone"
                            type="text"
                            placeholder={controllerData.phoneFieldTextFormOne}
                          />  
                          <label className="checkbox-inline">
                            <input type="checkbox" value="" required/> <Link to="/terms"> {controllerData.checkboxFieldTextFormOne} </Link>
                          </label>  
                      
                      <div className="submit-text">
                            <button className="btn btn-block">{controllerData.buttonCaptionTextFormOne}</button>
                            <p> <Icon icon={ic_chat_bubble_outline} size={24} /> {controllerData.afterButtonHelplineTextFormOne}</p>
                      </div>
                  </form>
                  </div>
              </Col>
         </Row>
      </Container>
      </div>
    </ControllerDataWrapper>
  );
};

// ServiceSection style props
ServiceSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// ServiceSection default style
ServiceSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['50px', '50px', '50px', '80px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#0091ae',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '80px'],
    height: ['70px', '80px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
    mt: '-5px',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
    mb: '10px',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
};

export default ServiceSection;
