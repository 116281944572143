import styled from 'styled-components';

const CompanyDataWrapper = styled.section`
  background-color: rgb(0,21,26);
  padding: 15px 0 40px;
  a{
    color: #000;
    &:hover {
      color: #000;
    }
  }
  
  @media (max-width: 990px) {
    padding: 60px 0 0 0;
  }

    .card-block{
        .card-left {
          margin-right: 10px;
        }
        .card-right {
          margin-left: 10px;
        }
        @media (max-width: 990px) {
          .card-left {
            margin-right: 0px;
          }
          .card-right {
            margin-left: 0px;
            margin-top: 15px;
            margin-bottom: 15px;
          }
        }
      .card{
        .card-img-top {
            border-top-left-radius: calc(5px - 0px);
            border-top-right-radius: calc(5px - 0px);
        }
        h2{
          font-family: "Runda W00 Bold" !important;
          padding-bottom: 18px;
        }
        .card-text {
            font-size: 16px;
        }
        border: 0px;
        min-height: 800px;
        border-radius: 5px;
        
    }

}`;

export default CompanyDataWrapper;
