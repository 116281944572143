import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import CompanyDataWrapper from './twoblocks.style';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { Link } from "gatsby";


const ServiceSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulBlockSetting {
        edges {
          node {
            blockOneImageBeforeFooterSection{
              fluid {
                 src
              }
            }
            blockOneTitleBeforeFooterSection
            blockOneLinkBeforeFooterSection
            blockOneDescriptionBeforeFooterSection{
              blockOneDescriptionBeforeFooterSection
            }

            blockTwoImageBeforeFooterSection{
              fluid(maxWidth: 1500){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            blockTwoTitleBeforeFooterSection
            blockTwoLinkBeforeFooterSection
            blockTwoDescriptionBeforeFooterSection{
              blockTwoDescriptionBeforeFooterSection
            }
            
          }
        }
      }
    }
  `);

  const companyData = Data.allContentfulBlockSetting.edges[0].node;

  return (
    <CompanyDataWrapper>
      <div className="card-block">
      <Container>
        <Row>
            <Col md={6} xs={12}>
              <Link to={companyData.blockOneLinkBeforeFooterSection}><Card className="card-left">
                <Card.Img variant="top" src={companyData.blockOneImageBeforeFooterSection.fluid.src} />
                <Card.Body>
                  <Card.Text>
                  <h2>{companyData.blockOneTitleBeforeFooterSection}</h2>
                    {companyData.blockOneDescriptionBeforeFooterSection.blockOneDescriptionBeforeFooterSection}
                  </Card.Text>
                </Card.Body>
              </Card></Link> 
            </Col>
            <Col md={6} xs={12}> 
            <Link to={companyData.blockTwoLinkBeforeFooterSection}><Card className="card-right">
                <Card.Img variant="top" src={companyData.blockTwoImageBeforeFooterSection.fluid.src} />
                <Card.Body>
                  <Card.Text>
                     <h2>{companyData.blockTwoTitleBeforeFooterSection}</h2>
                    {companyData.blockTwoDescriptionBeforeFooterSection.blockTwoDescriptionBeforeFooterSection}
                  </Card.Text>
                </Card.Body>
              </Card></Link> 
            </Col>
         </Row>
      </Container>
      </div>
    </CompanyDataWrapper>
  );
};

// ServiceSection style props
ServiceSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// ServiceSection default style
ServiceSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['50px', '50px', '50px', '80px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#0091ae',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '80px'],
    height: ['70px', '80px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
    mt: '-5px',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
    mb: '10px',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
};

export default ServiceSection;
