import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from '../../../reusecore/src/elements/Box';
import Heading from '../../../reusecore/src/elements/Heading';
import FeatureBlock from '../../../common/src/components/FeatureBlock';
import Container from '../../../common/src/components/UI/Container';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { navigate } from 'gatsby';
import { Icon } from 'react-icons-kit';
import {longArrowRight} from 'react-icons-kit/fa/longArrowRight';
import BannerWrapper, {
  TextWrapper,
} from './bannerSection.style';


const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
  bannerImage,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulBlockSetting {
        edges {
          node {
            bannerTitle
            bannerSubTitle {
              json
            }
            bannerButtonOneCaption
            bannerButtonTwoCaption
          }
        }
      }
    }
  `);
  
  const bannerData = Data.allContentfulBlockSetting.edges[0].node;

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => `<span class="boldtext">${text}</span>`
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content)}<p>`
    },
  }

  const ButtonGroup = () => (
    <Fragment>
    <button onClick={() => navigate("/opdracht-plaatsen/")} className="btn">{bannerData.bannerButtonOneCaption} <Icon icon={longArrowRight} size={18} /> </button>
    <button onClick={() => navigate("/professionals-aanmelden/")} className="btn">{bannerData.bannerButtonTwoCaption} <Icon icon={longArrowRight} size={18} /> </button>
    </Fragment>
  );

  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
          
            <FeatureBlock
              title={
                <Heading
                  content={bannerData.bannerTitle}
                  {...title}                  
                  fontWeight={600}
                  color='#33475b'
                />
              }
              description={
                <TextWrapper
                  dangerouslySetInnerHTML={{ __html: documentToHtmlString(bannerData.bannerSubTitle.json, options) }}
                  className="content__desc"
                />
              }
              button={<ButtonGroup />}
             
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '48px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '70px',
  },
  description: {
    fontSize: '16px',
    color: '#343d48',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    type: 'button',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    colors: 'primary',
    borderRadius: '4px',
    ml: '18px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};


export default BannerSection;
