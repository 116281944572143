import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Box from '../../../reusecore/src/elements/Box';
import CompanyDataWrapper from './company.style';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from "gatsby";


const ServiceSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulFormFields {
        edges{
          node{
            sectionTitleFormTwo
            leftBlockTitleFormTwo
            leftBlockLogoFormTwo {
              fluid(maxWidth: 1500){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            leftBlockTextFormTwo
            leftBlockButtonCaptionFormTwo
            leftBlockButtonLinkFormTwo
            rightBlockTitleFormTwo
            rightBlockSubTitleFormTwo
            companyFieldTextFormTwo
            nameFieldTextFormTwo
            emailFieldTextFormTwo
            phoneFieldTextFormTwo
            checkboxFieldTextFormTwo
            buttonCaptionTextFormTwo
          }
        }
      }
    }
  `);

  const companyData = Data.allContentfulFormFields.edges[0].node;

  return (
    <CompanyDataWrapper>
      <Container>
         <Box className="controllerData_head">
           <h2>{companyData.sectionTitleFormTwo}</h2>
        </Box>
      </Container>
      <div className="card-block">
      <Container>
        <Row>
             <Col md={4} xs={12}>
              <div className="left-card">
                <div className="card-head">
                <Img fluid={companyData.leftBlockLogoFormTwo.fluid} />
                  <h2>{companyData.leftBlockTitleFormTwo}</h2>
                </div> 
                <div className="cardbody">
                  <p>{companyData.leftBlockTextFormTwo}</p>
                  
                </div>
                <div className="card-foot">
                  <Link to={companyData.leftBlockButtonLinkFormTwo}><button className="btn btn-info btn-block btn-controller">{companyData.leftBlockButtonCaptionFormTwo}</button> </Link>
                </div>
              </div>  
            </Col>
            <Col md={8} xs={12}> 
            <div className="right-card">
             <Row>
                <Col>
                  <div className="form-details">
                    <h2>{companyData.rightBlockTitleFormTwo}</h2> 
                    <p>{companyData.rightBlockSubTitleFormTwo}</p>
                  </div>
                </Col> 
                <Col>
                  <form name="company" action="/opdracht-plaatsen-success" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                      <input type="hidden" name="bot-field" />
                      <input type="hidden" name="form-name" value="company" />
                     
                          <label htmlFor="inputcompany">{companyData.companyFieldTextFormTwo}</label>
                          <input
                            id="inputcompany"
                            className="form-control"
                            name="company"
                            type="text"
                            placeholder=""
                            required/> 
                          <label htmlFor="inputname">{companyData.nameFieldTextFormTwo}</label>
                          <input
                            id="inputname"
                            className="form-control"
                            name="name"
                            type="text"
                            placeholder=""
                            required/>  
                          <label htmlFor="inputemail">{companyData.emailFieldTextFormTwo}</label>
                          <input
                            id="inputemail"
                            className="form-control"
                            name="email"
                            type="email"
                            placeholder=""
                            required/>  
                          <label htmlFor="inputphone">{companyData.phoneFieldTextFormTwo}</label>
                          <input
                            id="inputphone"
                            className="form-control"
                            name="phone"
                            type="text"
                            placeholder=""
                          />  
                          <label className="checkbox-inline">
                            <input type="checkbox" value="" required/><Link to="/terms">{companyData.checkboxFieldTextFormTwo}</Link>
                          </label>  
                      
                      <div className="submit-text">
                            <button className="btn btn-info btn-block">{companyData.buttonCaptionTextFormTwo}</button>
                      </div>
                  </form>
                  </Col>
             </Row>
              </div>
            </Col>
         </Row>
      </Container>
      </div>
    </CompanyDataWrapper>
  );
};

// ServiceSection style props
ServiceSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// ServiceSection default style
ServiceSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['50px', '50px', '50px', '80px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#0091ae',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '80px'],
    height: ['70px', '80px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
    mt: '-5px',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
    mb: '10px',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
};

export default ServiceSection;
