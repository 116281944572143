import styled from 'styled-components';
import {
  color,
  width,
  height,
  display,
  space,
  borders,
  borderColor,
  boxShadow,
  borderRadius,
  flexWrap,
  alignItems,
  justifyContent,
  flexDirection,
  position,
  overflow,
  fontSize,
  textAlign,
} from 'styled-system';

// FeatureBlock wrapper style
const FeatureBlockWrapper = styled.div`


   @media (max-width: 500px) {
    .content__wrapper {
      h1{
        line-height: 35px !important;
      }
    }
  }
  &.icon_left {
    display: flex;
    .icon__wrapper{
      flex-shrink: 0;
    }
  }
  &.icon_right {
    display: flex;
    flex-direction: row-reverse;
    .content__wrapper {
      text-align: right;
    }
    .icon__wrapper{
      flex-shrink: 0;
    }
  }

  .icon__wrapper div {
    width: 60px;
  }
  
  /* styled system prop support */
  ${display}
  ${width}
  ${height}
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${justifyContent}
  ${position}
  ${color}
  ${space}
  ${borders}
  ${borderColor}
  ${boxShadow}
  ${borderRadius}
  ${overflow}
`;

// Icon wrapper style
const IconWrapper = styled.div`
  ${display}
  ${width}
  ${height}
  ${alignItems}
  ${justifyContent}
  ${position}
  ${color}
  ${space}
  ${borders}
  ${borderColor}
  ${boxShadow}
  ${borderRadius}
  ${overflow}
  ${fontSize}
`;

// Content wrapper style
const ContentWrapper = styled.div`
  ${width}
  ${space}
  ${textAlign}
  width: 83%;
  padding-left: 45px;
  h1{
      font-family:"Runda W00 Bold" !important;
      color: #262626;
      font-size: 4rem;
      font-weight: 700;
      line-height: 1.14286em;
      margin-left: -.04em;
      letter-spacing: -.04em;
  }
  .content__desc{
    opacity: 0.9;
    line-height: 1.3em;
    font-weight: 300;
    font-size: 32px;
    padding-right:0px;
  }
  
  :before{
        content: "";
        position: absolute;
        left: 15px;
        width: 4px;
        height: 100%;
        background: linear-gradient(45deg, rgb(32, 211, 64) 0%, rgb(0, 209, 237) 100%);
  }
 
   @media (max-width: 500px) {
      h1{
        font-size: 2rem;
      }
      .content__desc{
        line-height: 26px;
        font-size: 18px;
      }

  }

  .button__wrapper {
    button {
        color: #fff;
        background-image: none !important;
        padding: 12px 24px;
        font-size: 18px;
        min-height: 50px;
        font-family: "Runda W00 Bold" !important;
        background-color: #20d340;
        border-color: #20d340;
    }
    button:last-child {
      margin-left: 20px;
      background-color: transparent;
      color: #20d340;
      padding: 12px 24px;
      opacity: 1;
      border-color: transparent;
      :hover {
          background-color: rgba(32, 211, 64, 0.08);
      }
    }
  }

@media (max-width: 500px) {
  .content__desc{
    padding-right: 0px;
  }
}



`;

// Button wrapper style
const ButtonWrapper = styled.div`
  ${display}
  ${space}
  ${alignItems}
  ${flexDirection}
  ${justifyContent}
`;

export { IconWrapper, ContentWrapper, ButtonWrapper };
export default FeatureBlockWrapper;
