import styled from 'styled-components';

const ServiceSectionWrapper = styled.section`
:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 30px;
    left: calc(50% + 1px);
    border-right: 2px dotted rgba(0,0,0,0.15);
    z-index: -1;
}
  padding: 0px 0 150px;
  @media (max-width: 990px) {
    padding: 60px 0 0 0;
  }
.hrlogo {
    padding: 10px 0px;
    .controllerhr {
        width: 40%;
        margin: auto;
        img {
            display: block;
            margin: auto;
            opacity: 0.6;
        }
    }

}
.firstrow {
  padding: 25px 0px 50px;
  @media (max-width: 500px) {
    .col {
      width: 50%;
    }
  }
  .left {
      h2 {
          font-family: "Runda W00 Bold" !important;
          font-size: 3rem;
          line-height: 64px;
          margin-top: 10%;
      }
      @media (max-width: 500px) {
        h2 {
         font-size: 18px;
         line-height: 25px;
        }
      }  
  }
  .right{
    padding: 0px 0px 0px 25px;
    :before {
    content: "";
    position: absolute;
    left: 0px;
    width: 4px;
    height: 100%;
    background: linear-gradient(45deg, rgb(32, 211, 64) 0%, rgb(0, 209, 237) 100%);
    }
      h2 {
        font-family: "Runda W00 Bold" !important;
        font-size: 22px;
        padding-bottom: 22px;
      }
      p{
        color: #262626;
        font-size: 1rem;
        line-height: 1.71429em;
      }
      @media (max-width: 500px) {
        h2 {
         font-size: 16px;
         padding-bottom: 15px;
        }
        p {
         font-size: 14px;
        }
      } 
  }
}

.secoundrow {
  padding: 50px 0px;
  .left {
    padding: 46px 25px 0px 0px;
    text-align: right;
      .desc_text{
         font-size: 1.25rem;
         line-height: 28px;
       }
       h3 {
        color: #262626;
        font-size: .875rem;
        font-family: "Runda W00 medium" !important;
        padding: 0px;
        margin: 30px 0px 5px 0px;
       }  
       span {
          color: rgba(0, 0, 0, 0.65);
          font-size: .75rem;
          line-height: 1.20588em;
          text-transform: uppercase;
          letter-spacing: .15em;
       } 
       @media (max-width: 500px) {
        .desc_text {
          font-size: 16px;
        }
       }  
  }
  .right{
    padding: 0px 0px 0px 25px;
    h2 {
      font-family: "Runda W00 Bold" !important;
      font-size: 2rem;
      padding-bottom: 15px;
      padding-top: 20px;
    }
    p{
      color: #262626;
      font-size: 1rem;
      line-height: 1.71429em;
    }
    span {
        color: rgba(0, 0, 0, 0.65);
        font-size: .75rem;
        font-weight: 400;
        font-family: "roboto-mono",sans-serif;
        line-height: 1.20588em;
        text-transform: uppercase;
        letter-spacing: .15em;
        width: max-content;
        display: block;
        :after {
            margin-top: 2vh;
            display: block;
            content: "";
            height: 4px;
            background: linear-gradient(45deg, rgb(32, 211, 64) 0%, rgb(0, 209, 237) 100%);
        }
    }
    @media (max-width: 500px) {
        h2 {
         font-size: 16px;
         padding-bottom: 15px;
        }
        p {
         font-size: 14px;
        }
    } 
  }
}

.thirdrow {
  @media (max-width: 500px) {
  .col {
    width: 50%;
  }
}
  padding: 50px 0px;
  .left {
    text-align: right;
    padding: 46px 25px 0px 0px;
     .desc_text{
       font-size: 1.25rem;
       line-height: 28px;
     }
     h3 {
      color: #262626;
      font-size: .875rem;
      font-family: "Runda W00 medium" !important;
      padding: 0px;
      margin: 30px 0px 5px 0px;
     }  
     span {
      color: rgba(0, 0, 0, 0.65);
      font-size: .75rem;
      font-weight: 400;
      font-family: "roboto-mono",sans-serif;
      line-height: 1.20588em;
      text-transform: uppercase;
      letter-spacing: .15em;
     } 
     @media (max-width: 500px) {
        .desc_text {
          font-size: 16px;
        }
     }  
  }
  .right{
    padding: 0px 0px 0px 25px;
    h2 {
      font-family: "Runda W00 Bold" !important;
      font-size: 2rem;
      padding-bottom: 15px;
      padding-top: 20px;
    }
    p{
      color: #262626;
      font-size: 1rem;
      line-height: 1.71429em;
    }
    span {
        color: rgba(0, 0, 0, 0.65);
        font-size: .75rem;
        font-weight: 400;
        font-family: "roboto-mono",sans-serif;
        line-height: 1.20588em;
        text-transform: uppercase;
        letter-spacing: .15em;
        width: max-content;
        display: block;
        :after {
            margin-top: 2vh;
            display: block;
            content: "";
            height: 4px;
            background: linear-gradient(45deg, rgb(32, 211, 64) 0%, rgb(0, 209, 237) 100%);
        }
    }
    @media (max-width: 500px) {
        h2 {
         font-size: 16px;
         padding-bottom: 15px;
        }
        p {
         font-size: 14px;
        }
    }
  }
}

.fourthrow {
  padding: 50px 0px;
  .left {
    padding: 46px 25px 0px 0px;
    text-align: right;
    .desc_text{
      font-size: 1.25rem;
      line-height: 28px;

     }
     h3 {
      color: #262626;
      font-size: .875rem;
      font-family: "Runda W00 medium" !important;
      padding: 0px;
      margin: 30px 0px 5px 0px;
     }  
     span {
     color: rgba(0, 0, 0, 0.65);
    font-size: .75rem;
    font-weight: 400;
    font-family: "roboto-mono",sans-serif;
    line-height: 1.20588em;
    text-transform: uppercase;
    letter-spacing: .15em;
     }
     @media (max-width: 500px) {
        .desc_text {
          font-size: 16px;
        }
     }  
  }
  .right{
    padding: 0px 0px 0px 25px;
    h2 {
      font-family: "Runda W00 Bold" !important;
      font-size: 2rem;
      padding-bottom: 15px;
      padding-top: 20px;
    }
    p{
      color: #262626;
      font-size: 1rem;
      line-height: 1.71429em;
    }
    span {
        color: rgba(0, 0, 0, 0.65);
        font-size: .75rem;
        font-weight: 400;
        font-family: "roboto-mono",sans-serif;
        line-height: 1.20588em;
        text-transform: uppercase;
        letter-spacing: .15em;
        width: max-content;
        display: block;
        :after {
            margin-top: 2vh;
            display: block;
            content: "";
            height: 4px;
            background: linear-gradient(45deg, rgb(32, 211, 64) 0%, rgb(0, 209, 237) 100%);
        }
    }
    @media (max-width: 500px) {
        h2 {
         font-size: 16px;
         padding-bottom: 15px;
        }
        p {
         font-size: 14px;
        }
    }
  }
}




`;

export default ServiceSectionWrapper;
