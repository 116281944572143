import styled from 'styled-components';

const CompanyDataWrapper = styled.section`
  background-color: rgb(0,21,26);
  padding: 30px 0 0px;
  .controllerData_head {
      text-align: center;
      h2{
        font-family: "Runda W00 Bold" !important;
        padding-bottom: 10px;
        color: #fff;
      }
      h3 {
        font-size: 18px;
        color: #fff;
      }
  }
  @media (max-width: 990px) {
    padding: 60px 0 0 0;
  }

.card-block{
  padding: 30px 0px;
  .left-card {
    background-color: #fff;
    box-shadow: 0 4px 23px 0 rgba(0,0,0,0.15);
    border-radius: 5px;
      .card-head {
        .gatsby-image-wrapper {
  		       width: 150px;
             margin: 10px auto;
  		  }
        padding: 15px 0px 0px;
        border-bottom: 1px solid #0000001f;
        h2 {
            font-size: 21px;
			       font-family: Runda W00 Bold !important;
			       padding: 18px 0px;
			       margin: 0px;
			       text-align: center;
          }
        }
        .cardbody{
        	padding: 16px;
          min-height: 188px;
            h2 {
              font-size: 33px;
              font-family: "Runda W00 Bold" !important;
              text-align: center;
              border-bottom: 1px solid #0000001f;
              padding: 20px 0px 20px 0px;
           }
           ul{
              padding: 15px 20px;
                svg {
                  fill: rgb(32, 211, 64);
                  stroke: #fff;
                  margin-right: 10px;
                }
           }
        }
        .card-foot {
            text-align: center;
            padding: 20px;
            a {
                color: #000;
                font-weight: bold;
            }
            .btn-controller {
                background-color: #20d340 !important;
                border: #20d340 !important;
                padding: 13px 0px;
                font-family: "Runda W00 Bold" !important;
                font-size: 18px;
            }
        }
    }
    .right-card{
      background: linear-gradient(45deg, rgb(16, 210, 151) 0%, rgb(0, 209, 237) 100%);
      padding: 20px 35px 10px 35px;
      margin-left: 15px;
      border-radius: 5px;
      @media (max-width: 500px) {
         margin-left: 0px;
         margin-top: 15px;
      }
      .form-details{
      	  padding: 40% 0px;
  	      h2 {
    		    font-family: "Runda W00 Bold" !important;
    		    color: #fff;
  		    }
    		  p{
    		  	color: #fff;
    		  }
      } 
      label {
        color: #fff;
        margin-bottom: 0px !important;
        font-size: 14px;
      }
      input {
          background-color: transparent !important;
          border-radius: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          padding-left: 0px;
          color: #ffffff;
          font-family: "Runda W00 Medium" !important;
          font-size: 22px;
          :focus {
            box-shadow: none;
          }
          :-internal-autofill-selected {
              background-color: #000 !important;
          }
          height: 30px;
          margin-bottom: 10px;
      }
      .submit-text{
        text-align: center;
        p {
          font-size: 19px;
          color: #fff;
          font-family: "Runda W00 bold" !important;
          margin-top: 13px;
        }
        button {
    			background-color: #fff;
    			border-color: #fff;
    			color: #000;
    			padding: 10px 0px;
    			font-size: 20px;
    			font-family: "Runda W00 Bold" !important;
        }
      }
      .checkbox-inline {
        margin-top: 0px;
        text-transform: capitalize;
        vertical-align: super;
          input[type="checkbox"]{
            width: 15px;
            height: 15px;
            cursor: pointer;
            vertical-align: sub;
            margin-right: 10px;
          }
          a {
            color: #fff !important;
          }
      } 
    }
} `;

export default CompanyDataWrapper;
