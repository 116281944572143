import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ServiceSectionWrapper from './service.style';
import { Container, Col, Row } from 'react-bootstrap';
const ServiceSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulAfterBannerServices {
        edges {
          node {
            mainTitleLeftBlockFirstRow
            titleRightBlockFirstRow
            descriptionRightBlockFirstRow {
              descriptionRightBlockFirstRow
            }
            textLeftBlockSecondRow{
              textLeftBlockSecondRow
            }
            personNameLeftBlockSecondRow
            personDesignationLeftBlockSecondRow
            titleRightBlockSecondRow
            subTitleRightBlockSecondRow
            descriptionRightBlockSecondRow {
              descriptionRightBlockSecondRow
            }
            textLeftBlockThirdRow{
              textLeftBlockThirdRow
            }
            personNameLeftBlockThirdRow
            personDesignationLeftBlockThirdRow
            titleRightBlockThirdRow
            subTitleRightBlockThirdRow
            descriptionRightBlockThirdRow {
              descriptionRightBlockThirdRow
            }
            textLeftBlockFourthRow {
              textLeftBlockFourthRow
            }
            personNameLeftBlockFourthRow
            personDesignationLeftBlockFourthRow
            titleRightBlockFourthRow
            subTitleRightBlockFourthRow
            descriptionRightBlockFourthRow {
              descriptionRightBlockFourthRow
            }
          }
        }
      }
    }
  `);
  const serviceDatatitle = Data.allContentfulAfterBannerServices.edges[0].node;
  return (
    <ServiceSectionWrapper>
      <Container>
        

        <div className="firstrow">
          <Row>
              <Col>
                <div className="left">
                  <h2>{serviceDatatitle.mainTitleLeftBlockFirstRow}</h2>
                </div>
              </Col>
              <Col>
                  <div className="right">
                    <h2>{serviceDatatitle.titleRightBlockFirstRow}</h2>
                    <p>{serviceDatatitle.descriptionRightBlockFirstRow.descriptionRightBlockFirstRow}</p>
                  </div>
              </Col>
          </Row>
        </div>
        

        <div className="secoundrow">
          <Row>
              <Col>
                <div className="left">
                  <p className="desc_text">{serviceDatatitle.textLeftBlockSecondRow.textLeftBlockSecondRow}</p>
                  <h3>{serviceDatatitle.personNameLeftBlockSecondRow}</h3>
                  <span>{serviceDatatitle.personDesignationLeftBlockSecondRow}</span>
                </div>
              </Col>
              <Col>
                <div className="right">
                 <span className="subtitle">{serviceDatatitle.subTitleRightBlockSecondRow}</span>
                  <h2>{serviceDatatitle.titleRightBlockSecondRow}</h2>
                  <p>{serviceDatatitle.descriptionRightBlockSecondRow.descriptionRightBlockSecondRow}</p>
                </div>
              </Col>
          </Row>
        </div>
        

        <div className="thirdrow">
          <Row>
              <Col>
                <div className="left">
                  <p className="desc_text">{serviceDatatitle.textLeftBlockThirdRow.textLeftBlockThirdRow}</p>
                  <h3>{serviceDatatitle.personNameLeftBlockThirdRow}</h3>
                  <span>{serviceDatatitle.personDesignationLeftBlockThirdRow}</span>
                </div>
              </Col>
              <Col>
                <div className="right">
                 <span className="subtitle">{serviceDatatitle.subTitleRightBlockThirdRow}</span>
                  <h2>{serviceDatatitle.titleRightBlockThirdRow}</h2>
                  <p>{serviceDatatitle.descriptionRightBlockThirdRow.descriptionRightBlockThirdRow}</p>
                </div>
              </Col>
          </Row>
        </div>
        


        <div className="fourthrow">
          <Row>
              <Col>
                <div className="left">
                  <p className="desc_text">{serviceDatatitle.textLeftBlockFourthRow.textLeftBlockFourthRow}</p>
                  <h3>{serviceDatatitle.personNameLeftBlockFourthRow}</h3>
                  <span>{serviceDatatitle.personDesignationLeftBlockFourthRow}</span>
                </div>
              </Col>
              <Col>
                <div className="right">
                  <span className="subtitle">{serviceDatatitle.subTitleRightBlockFourthRow}</span>
                  <h2>{serviceDatatitle.titleRightBlockFourthRow}</h2>
                  <p>{serviceDatatitle.descriptionRightBlockFourthRow.descriptionRightBlockFourthRow}</p>
                </div>
              </Col>
          </Row>
        </div>


      </Container>
    </ServiceSectionWrapper>
  );
};

// ServiceSection style props
ServiceSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// ServiceSection default style
ServiceSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['50px', '50px', '50px', '80px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#0091ae',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '80px'],
    height: ['70px', '80px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
    mt: '-5px',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
    mb: '10px',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
};

export default ServiceSection;
